import { useState, useEffect } from 'react';
import { Link } from '@inertiajs/react';
import { CardItemProps, GalleryCardItemProps, ShopCartProps } from './component';
import { 
    Minus, 
    Plus,
    Trash2,
    Heart
} from 'lucide-react';

import { useAppDispatch, setBagItems, deleteBagItem } from '@/redux/store';

import {
    Dialog,
    DialogContent
  } from "@/Components/ui/dialog"

import { SwiperCard } from './SwiperCard';

export function ProductCard({ href, images = [], image, title, subtitle, status, price, sidePriceText, isPlaceholderImage }: CardItemProps) {
    const [cardHover, setCardHover] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<string>(image ? image  : images[0]);
   
    return (
        <Link
            href={href!}
            onMouseEnter={() => setCardHover(true)}
            onMouseLeave={() => setCardHover(false)}
        >
            <div className='w-100'>
                <img 
                    src={currentImage} 
                    alt={title} 
                    className="card-image block" 
                />
                <div className='cursor-pointer'>
                    {isPlaceholderImage && (cardHover && images!.length > 1) ? (
                        <div className='flex space-x-2 pt-[15px]'>
                            {images!.length > 0 && images!.map((imageItem, index) => (
                                <img 
                                    key={index}
                                    src={imageItem} 
                                    alt={title} 
                                    width={50}
                                    onMouseEnter={() => setCurrentImage(imageItem)}
                                />
                            ))}
                        </div>
                    ): (
                        <>
                            {title && (
                                <div className='pt-[15px]'>
                                    <h2 className='text-[16px] font-[700] leading-[21.6px] text-black mb-2'>{title}</h2>
                                    <p className="text-[16px] font-[400] leading-[21.6px] text-gray-700 mb-1">{subtitle}</p>
                                    {status && <p className="text-[16px] font-[400] leading-[21.6px] text-gray-700 mb-1">{status}</p>}
                                </div>
                            )}
                        </>
                    )}
                    {price && (
                        <div className='flex my-4 items-baseline'>
                            {sidePriceText && <p className='text-[12px] text-black font-[400] pr-[8px]'>{sidePriceText}</p> }
                            <p className="text-[16px] text-black font-semibold">{price}</p>
                        </div>
                    )}
                </div>
            </div>
        </Link>
    )
} 

export function CategoryCard({ href, image, title, buttonText }: CardItemProps) {
    return (
        <Link
            href={href!}
        >
                <div className='relative bg-black bg-opacity-50 shadow-md overflow-hidden w-100'>
                    <img src={image} alt={title} className="card-image block" />
                    <div className='pt-[15px] cursor-pointer absolute inset-0 flex flex-col justify-end my-7 mx-8 space-y-4'>
                        <h2 className='text-[24px] font-[700] leading-[21.6px] text-white mb-2'>{title}</h2>
                        <button className="bg-white w-fit font-[600] text-black text-[14px] rounded-full pt-[8px] pr-[45px] pb-[8px] pl-[45px]" >{buttonText}</button>
                    </div>
                </div>
        </Link>
    )
} 

export function GalleryCard({ title, images }: GalleryCardItemProps) {
    const [openGallery, setOpenGallery] = useState<boolean>(false);

    return (
        <>
            {images?.length > 0 && images?.map((item, index) => (
                <div className="relative w-100 h-100" key={index}>
                    <img
                        src={item.image} 
                        alt={title}
                        className="w-full h-full object-cover"
                    />
                    <button
                        onClick={() => setOpenGallery(true)}
                        className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300"
                    >
                        <Plus className='bg-gray-200 text-black rounded-full p-4 hover:bg-gray-100' size={50} />
                    </button>
                </div>
            ))}
            <Dialog open={openGallery} onOpenChange={setOpenGallery}>
                <DialogContent className='block max-h-full max-w-screen'>
                    <div className="container block px-4 py-4">
                        <SwiperCard 
                            type="product" 
                            cardData={images}
                            numberSliderPerView={[1,2,2]}
                            isNavigation={true}
                            navigationPosition={'center'}
                            isPagination={false}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
} 

export function ShopCard({ bagItems, size, isCount, isRemove, isFavorite }: ShopCartProps) {
    const dispatch = useAppDispatch();

    const minValue = 0;
    const maxValue = 10;

    const handleIncrementCounter = (id: string, amount: number) => {
        if (amount < maxValue) {

            const bagItems = [{
                id: id,
                amount: amount + 1,
            }];

            dispatch(setBagItems(bagItems))
        }
    };
  
    const handleDecrementCounter = (id: string, amount: number) => {
        if (amount > minValue) {
            const newAmount = amount - 1
            const bagItems = [{
                id: id,
                amount: amount - 1
            }];

            if(newAmount == 0){
                handleRemoveItem(id);
            }else{
                dispatch(setBagItems(bagItems))
            }

        }
    };

    const handleRemoveItem = (id: string) => {
        dispatch(deleteBagItem(id))
    }

    let cardClassName: string
    let imageSize: string;
    let titleSize: string;
    let priceSize: string;
    let subTitleSize: string;
    let countIconSize: number;
    let countClassName: string;
    let proccessIconSize: number;

    if(size == 'normal'){
        cardClassName = 'flex py-4'
        imageSize = 'h-24 w-24 rounded-md';
        titleSize = 'text-[14px] font-[600] leading-[16.1px] tracking-[0.1px]';
        priceSize = 'text-[14px] font-[600]';
        subTitleSize = 'text-[13px] text-fire-gray font-[400] leading-[14.3px]';
        countIconSize = 14;
        countClassName = 'mx-3 lg:mx-2'
        proccessIconSize = 18;
    }
    else if(size == 'large'){
        cardClassName = 'flex py-4 border-b border-gray-200'
        imageSize = 'h-24 w-24 md:h-40 md:w-40 rounded-none';
        titleSize = 'text-[16px] font-[600] leading-[20px] tracking-[0.1px]';
        priceSize = 'text-[15px] font-[400]';
        subTitleSize = 'text-[15px] text-fire-gray font-[400] leading-[18.75px] text-balance';
        countIconSize = 14;
        countClassName = 'my-2 mx-1 sm:mx-3 lg:mx-2'
        proccessIconSize = 24;
    }else {
        cardClassName = 'flex py-2'
        imageSize = 'h-16 w-16 rounded-md';
        titleSize = 'text-[14px] font-[600] leading-[16.1px] tracking-[0.1px]';
        priceSize = 'text-[14px] font-[600]';
        subTitleSize = 'text-[13px] text-fire-gray font-[400] leading-[14.3px]';
        countIconSize = 14;
        countClassName = 'mx-3 lg:mx-2'
        proccessIconSize = 18;
    }

    // checkout sayfasındaki card için
    const onlyShowCard = !isCount && !isRemove && !isFavorite

    return (
        <ul role="list" className="flow-root -my-6 py-6">
            {bagItems.map((item, index) => (
                <li key={index} className={cardClassName}>
                    <div className={`${imageSize} ${onlyShowCard ? 'relative' : 'overflow-hidden' }  flex-shrink-0 border border-gray-200`}>
                        {onlyShowCard && 
                            <div 
                                className='w-6 h-6 flex absolute -top-3 -right-2 bg-[#0000008f] rounded-full items-center justify-center text-white text-[12px] font-[600] '
                            >{item.amount}</div>
                        } 
                        <img src={item.image} alt={item.title} className=" h-full w-full object-cover object-center" />
                    </div>
                    <div className={`ml-4 flex flex-1 flex-col ${size == 'large'  && 'ml-2 lg:ml-6 justify-center' }`}>
                        <div>
                            <div className="flex justify-between items-baseline text-base font-medium text-gray-900">
                                <Link
                                    href={'/product'}
                                    className={`${titleSize} text-start`}
                                >{item.title}</Link>
                                <p className={`ml-4 text-right ${priceSize}`}>${item.price * item.amount}</p>
                            </div>
                            <p className={`mt-1 text-start w-fit lg:w-[83%] tracking-normal ${subTitleSize}`}>
                                {item.subTitle.map((subItem, subIndex) => (
                                    <>
                                        {subItem}{subIndex == (item.subTitle.length - 1) ? '' : ' / '}
                                    </>
                                ))}
                            </p>
                        </div>
                        <div className={`flex items-end text-sm ${size == 'large' ? 'justify-start space-x-8 mt-4 items-center' : 'flex-1 justify-between' } `}>
                            {isCount && (
                                <div 
                                    className="flex w-min space-x-4 items-center bg-white rounded-full overflow-hidden border border-gray-200 hover:border-black"
                                >
                                    <button 
                                        onClick={() => {handleDecrementCounter(item.id, item.amount )}}
                                    >
                                        <Minus size={countIconSize} className={countClassName} />
                                    </button>
                                    <p className='items-center justify-center w-4'>{item.amount}</p>
                                    <button 
                                        className="mx-2" 
                                        onClick={() => {handleIncrementCounter(item.id, item.amount)}}
                                    >
                                        <Plus size={countIconSize} className={countClassName} />
                                    </button>
                                </div>
                            )}
                            <div className="flex">
                                {isFavorite && (
                                    <button
                                        onClick={() => handleRemoveItem(item.id)}
                                    >
                                        <Heart size={proccessIconSize} className='mr-4' />
                                    </button>
                                )}
                                {isRemove && (
                                    <button
                                        onClick={() => handleRemoveItem(item.id)}
                                    >
                                        <Trash2 size={proccessIconSize} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}