import { useRef, useEffect } from "react";

import { Swiper as SwiperType, NavigationOptions } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ChevronLeft, ChevronRight } from "lucide-react";

import { CardItemProps } from "./component";
import { ProductCard, CategoryCard } from "./Cards";

interface SwiperCardProp {
    type?: "product" | "category";
    navigationPosition?: "top" | "center";
    isNavigation: boolean;
    isPagination: boolean;
    numberSliderPerView: number[];
    header?: string;
    cardData: CardItemProps[];
}

/* 
- numberSliderPerView parametresi görüntüleme başına slider sayısını belirtir. type olarak array, 
  değerleri ise number
  
  [0] => mobil ekranlar
  [1] => tablet ekranlar
  [2] => desktop ekranlar
*/

export function SwiperCard({
    type,
    isNavigation,
    navigationPosition = "top",
    isPagination,
    numberSliderPerView,
    header,
    cardData,
}: SwiperCardProp) {
    const prevButtonRef = useRef<HTMLButtonElement | null>(null);
    const nextButtonRef = useRef<HTMLButtonElement | null>(null);
    const swiperInstance = useRef<SwiperType | null>(null);

    useEffect(() => {
        if (swiperInstance.current && isNavigation) {
            const swiper = swiperInstance.current;

            if (
                swiper.params.navigation &&
                typeof swiper.params.navigation !== "boolean"
            ) {
                const navigationParams = swiper.params
                    .navigation as NavigationOptions;

                if (navigationPosition == "top") {
                    navigationParams.prevEl = prevButtonRef.current;
                    navigationParams.nextEl = nextButtonRef.current;
                } else {
                    navigationParams.prevEl = ".swiper-button-prev";
                    navigationParams.nextEl = ".swiper-button-next";
                }

                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
            }
        }
    }, [isNavigation, cardData]);

    let swiperNavigation;

    if (navigationPosition == "top") {
        swiperNavigation = {
            enabled: isNavigation,
            prevEl: prevButtonRef.current,
            nextEl: nextButtonRef.current,
            disabledClass: "swiper-button-disabled",
        };
    } else {
        swiperNavigation = {
            enabled: isNavigation,
            disabledClass: "swiper-button-disabled",
        };
    }

    return (
        <div
            className={`justify-center ${
                isNavigation && header ? "my-[80px]" : "my-0"
            }`}
        >
            {header && (
                <div className="grid grid-cols-12 my-4 items-center">
                    <div className="col-span-8 flex items-center space-x-4">
                        <h1 className="[font-size:_clamp(1.3rem,1.2vw,2.5rem)] font-bold leading-[28px] lg:pr-4 border-0 lg:border-r lg:border-b-gray-900">
                            {header}
                        </h1>
                        <a href="#" className="hidden lg:block">
                            Tümünü görüntüle
                        </a>
                    </div>
                    {isNavigation && navigationPosition == "top" && (
                        <div className="col-span-4 justify-end flex swiper-nav-btns space-x-2 text-right">
                            <button
                                ref={prevButtonRef}
                                className="bg-gray-300 rounded-full p-3 swiper-button image-swiper-button-prev"
                            >
                                <ChevronLeft />
                            </button>
                            <button
                                ref={nextButtonRef}
                                className="bg-gray-300 rounded-full p-3 swiper-button image-swiper-button-next"
                            >
                                <ChevronRight />
                            </button>
                        </div>
                    )}
                </div>
            )}
            <Swiper
                key={JSON.stringify(cardData)}
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={numberSliderPerView[2]}
                className="w-full"
                navigation={swiperNavigation}
                pagination={{
                    enabled: isPagination,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: numberSliderPerView[0],
                    },
                    600: {
                        slidesPerView: numberSliderPerView[1],
                    },
                    900: {
                        slidesPerView: numberSliderPerView[2],
                    },
                }}
                onSwiper={(swiper) => {
                    swiperInstance.current = swiper;
                }}
            >
                {cardData.map((card, index) => (
                    <SwiperSlide key={index}>
                        {type == "product" ? (
                            <ProductCard
                                href={card.href}
                                image={card.image}
                                title={card.title}
                                subtitle={card.subtitle}
                                sidePriceText={card.sidePriceText}
                                price={card.price}
                            />
                        ) : (
                            <CategoryCard
                                href={card.href}
                                image={card.image}
                                title={card.title}
                                subtitle={card.subtitle}
                                price={card.price}
                                buttonText={card.buttonText}
                            />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
